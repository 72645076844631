
.content{ 
    margin: 0 auto;
    padding: 4rem 0;
    width: 960px;
    max-width: 100%;
}
article {
    float: left;
    width: 720px;
}
article p:first-of-type {
    margin-top: 0;
}
aside {
    float: right;
    width: 120px;
}
p img {
    max-width: 100%;
}

@media only screen and (max-width: 960px) {
    .content{ 
        padding: 2rem 0;
    }
    article {
        float: none;
        margin: 0 auto;
        width: 96%;
    }
    article:last-of-type {  
        margin-bottom: 3rem;
    }
    aside {  
        float: none;
        text-align: center;
        width: 100%;
    }
}
