.blog p {
  font-size: 14px !important;
  /* font-weight: 100; */
  text-align: start !important;
}
.recent-post a {
  font-size: small !important;
  /* font-weight: 100; */
  text-align: start !important;
  font-weight: 700 !important;
}

a {
  color: black !important;
  font-size: small;
}
.team-item .team-img img,
.blog-item .blog-img img {
  transition: 0.5s;
}

.team-item:hover .team-img img,
.blog-item:hover .blog-img img {
  transform: scale(1.15);
}
