.gallery-container {
  max-width: auto;
  margin: 0 auto;
  padding: 20px;
}


.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
}

.image-card {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.image-card img {
  width: 100%;
  height: 300px;
  transition: transform 0.3s;
  cursor: pointer;
  object-fit: cover;
}

.image-card:hover img {
  transform: scale(1.1);
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  padding: 20px;
  text-align: center;
}

.modal img {
  max-width: 100%;
  max-height: 80vh;
  margin: 0 auto;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
}
