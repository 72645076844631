@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body,
html,
#root,
.App,
.App > div {
  height: 100vh;
  background-color: #f7fafc !important;
}
body,
html,
#root,
.App > div > div:last-child {
  margin-top: auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.margin {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  margin: 0 !important;
}

.css-v6xxo0 {
  background-color: #f2f2f2;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  margin: 0 !important;
}
.cloudc .css-v6xxo0 {
  background-color: white !important;
}
.css-1t5qs84 {
  margin: 0 !important;
}

.benefits {
  background-color: #f2f2f2;
}
.os-container {
  width: 100%;
  background-color: #f2f2f2;
}
.os-container > h1 {
  text-align: center;
  font-size: 20px;
  font-weight: 900;
}
.os-container > p {
  text-align: center;
  width: 100%;
  max-width: 700px;
  margin: 20px auto !important;
}
.gal-cont {
  max-width: 1300px;
  margin: auto !important;
  justify-content: center;
  padding-top: 50px !important;
}
.react-images__view--isModal img {
  margin: auto !important;
}
.gal-cont > div > div {
  justify-content: center;
  gap: 20px;
}
.gal-cont > div > div img {
  width: 290px !important;
  height: 190px !important;
  object-fit: cover;
}
.os-container1 {
  width: 100%;
  background-color: white;
}
.os-container1 > h1 {
  text-align: center;
  font-size: 20px;
  font-weight: 900;
}
.os-container1 > p {
  text-align: center;
  width: 100%;
  max-width: 700px;
  margin: 20px auto !important;
}

.os-inner-container {
  max-width: 1100px;
  width: 100%;
  background-color: #e4e4e7;
  margin: auto !important;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1px;
}
.os-inner-container > div {
  min-height: 300px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}
.os-inner-container > div > img {
  width: 70px;
}
.os-inner-container > div > h1 {
  font-weight: 900;
  margin: 30px 0px;
}

.iUSokj {
  position: fixed !important;
  left: 0;
  top: 0;
  right: 0;
}
.css-14rxmsw,
.css-6e3h0h {
  position: fixed !important;
  bottom: 0 !important;
  top: 0 !important;
  height: 100% !important;
  max-height: 100% !important;
}

.os-inner-container1 {
  max-width: 1100px;
  width: 100%;
  background-color: #e4e4e7;
  margin: auto !important;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1px;
}
.os-inner-container1 > div {
  min-height: 300px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}
.os-inner-container1 > div > img {
  width: 70px;
}
.os-inner-container1 > div > h1 {
  font-weight: 900;
  margin: 30px 0px;
}
.css-crq9r4 {
  display: flex !important;
  flex-wrap: wrap;
  width: 100vw !important;
  justify-content: space-evenly;
  margin: auto;
}
.footerNavLink > h2 {
  text-align: center;
  margin: auto;
  width: 100% !important;
  margin: 0 !important;
}
.footerNavLink > h2.korefat {
  text-align: left;
}
.footerNavLink > div {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
  flex: 1;
  overflow: hidden;
}
.footerNavLink > div > div {
  display: flex;
  flex-direction: column;
}
.footerNavLink a {
  font-size: 12px;
  /* padding: 5px; */
  padding-top: 10px !important;
  margin-top: 8px !important;
}

.card-cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  background-color: whitesmoke;
  overflow: hidden;
  border-radius: 15px;
  shape-outside: margin-box;
  box-shadow: 1px 3px 3px 0px rgba(20, 20, 0, 0.4);
}
.service-card-cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* max-width:400px; */
  background-color: #f2f2f2;
  overflow: hidden;
  background-color: #f2f2f2;
  border: none !important;
  outline: 0 !important;
  padding: 5px;
  /* border-radius: 15px;#e4e4e7 */
}

.heading-itpark {
  font-family: "Dangrek";
  color: white;
  font-weight: 900;
  font-size: 25px;
  text-align: center;
}
.itpark-content {
  font-size: 10px;
}
.itpark-m {
  padding: 20px;
}
.css-jt5kcz,
.css-l0nv4v,
.css-zjsk8o,
.css-9n5v3m,
.css-135e2oz,
.css-zjsk8o,
.css-1k60fyg,
.css-13pw97q,
.css-1iqvr2b,
.css-1ek2uml,
.css-heatre,
.css-nfgr2r,
.css-1oah76i,
.css-1riw79p {
  background-attachment: scroll !important;
  background-position: top;
  background-size: 100% auto !important;
}

@media only screen and (max-width: 600px) {
  .css-jt5kcz,
  .css-l0nv4v,
  .css-zjsk8o,
  .css-9n5v3m,
  .css-135e2oz,
  .css-zjsk8o,
  .css-1k60fyg,
  .css-13pw97q,
  .css-1iqvr2b,
  .css-1ek2uml,
  .css-heatre,
  .css-nfgr2r,
  .css-1oah76i,
  .css-1riw79p {
    background-attachment: scroll !important;
    background-position: top;
    background-size: auto 100% !important;
  }
}
@media only screen and (max-width: 1500px) {
  .map-cont {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    gap: 100px;
  }
  .map-cont > * {
    margin-bottom: 35px;
  }
}
* {
  scroll-behavior: smooth;
}
.css-1qg44r0 {
  padding: 0 !important;
}

.benefits margin {
  margin: auto !important;
}

.IconButton {
  color: white !important;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 150% !important;
}
@media only screen and (max-width: 600px) {
  body {
    zoom: 0.8;
    /* grid-template-columns: repeat(auto-fit,minmax(200px ,1fr)) !important; */
  }
}

p {
  text-align: justify;
}

.card-cont-dmpo {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 220px;
  background-color: whitesmoke;
  overflow: hidden;
  border-radius: 15px;
  shape-outside: margin-box;
  box-shadow: 1px 3px 3px 0px rgba(20, 20, 0, 0.4);
}

.card-cont-dmpo:hover .dmpo2 {
  display: block !important;
}
.card-cont-dmpo:hover .dmpo1 {
  display: none !important;
}
.card-cont-dmpo .dmpo2 {
  display: none !important;
  transition: background-color 2s ease-out 100ms;
}
.card-cont-dmpo .dmpo1 {
  display: block !important;
}

.wrapper {
  display: flex;
  width: 90%;
  justify-content: space-around;
}

.card {
  width: 30%;
  min-width: 300px;
  height: 340px;
  border-radius: 15px;
  padding: 1.5rem;
  /* background: white; */
  position: relative;
  display: flex;
  align-items: flex-end;
  transition: 0.4s ease-out;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
}
.card:hover {
  transform: translateY(20px);
}
.card:hover:before {
  opacity: 1;
}
.card:hover .info {
  opacity: 1;
  transform: translateY(0px);
}
.card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
}
.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.card .info {
  position: relative;
  z-index: 3;
  color: white;
  opacity: 0;
  transform: translateY(30px);
  transition: 0.5s;
}
.card .info h1 {
  margin: 0px;
}
.card .info p {
  letter-spacing: 1px;
  font-size: 15px;
  margin-top: 8px;
}

.container {
  width: 1200px;
  /* display: flex; */
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 15px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.card_dmpo {
  position: relative;
  width: 350px;
  height: 300px;

  margin: 0 auto;
  background: #fff;
  /* box-shadow: 0 15px 60px rgba(0,0,0, .5); */
  box-shadow: 1px 3px 3px 0px rgba(20, 20, 0, 0.5);
  border-radius: 15px;
}

.card_dmpo .face {
  position: absolute;

  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_dmpo .face.face1 {
  box-sizing: border-box;
  padding: 20px;
}

.card_dmpo .face.face1 h2 {
  margin: 0;
  padding: 0;
}

.card_dmpo .face.face1 .content {
  font-size: 0.8em;
  margin: 0;
  padding: 0 0 1em 0;
  font-weight: 500;
}

.card_dmpo .face.face2 {
  background: #111;
  transition: 0.5s;
}

.card_dmpo:nth-child(1) .face.face2 {
  background-color: whitesmoke;
  border-radius: 15px;
}

.card_dmpo:nth-child(2) .face.face2 {
  background-color: whitesmoke;
  border-radius: 15px;
}

.card_dmpo:nth-child(3) .face.face2 {
  background-color: whitesmoke;
  /* opacity: 95%; */
  border-radius: 15px;
}

.card_dmpo:hover .face.face2 {
  height: 60px;
  border-radius: 0 0 15px 15px;
}

.card_dmpo .face.face2:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px 0 0 15px;
}

.card_dmpo .face.face2 h2 {
  margin: 0;
  padding: 0;
  font-size: 0.9em;
  color: black;
  transition: 0.5s;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

  text-align: center;
  /* font-weight: bold; */
}

.card_dmpo:hover .face.face2 h2 {
  font-size: 0.8em;
  color: black;
  /* font-weight: bold; */
}
.css-n21gh5 > div {
  margin: 0 !important;
}
.footerlink:nth-child(3) > p {
  opacity: 0;
}

.itpark {
  position: relative;
}
nav a.active {
  /* color: #70e304 !important; */
}
.itpark:hover {
  position: static;
}
.itpark:hover .dropdown {
  opacity: 1;
}
.itpark .dropdown a:last-child {
  border: none;
}
.itpark .dropdown a {
  display: block;
  width: 100%;
  padding: 5px;
  border-bottom: 1px solid rgb(0 0 0 / 0.2);
}
.itpark .dropdown {
  opacity: 0;
  padding: 5px;
  background-color: white;
  position: absolute;
  width: 100px;
  margin-left: -10px;
  padding-top: 25px;
  height: auto;
  width: 150px;

  font-size: 0.8rem;
}
@media screen and (max-width: 766px) {
  .itpark .dropdown {
    display: none !important;
    opacity: 1;
    position: relative;
    background-color: white;
    left: 0;
    margin-top: 0;
    margin-left: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100% !important;
  }

  .itpark:hover .dropdown {
    display: block !important;
  }
}

.container {
  width: 1200px;
  /* display: flex; */
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 15px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.card_dmpo {
  position: relative;
  width: 350px;
  height: 300px;

  margin: 0 auto;
  background: #fff;
  /* box-shadow: 0 15px 60px rgba(0,0,0, .5); */
  box-shadow: 1px 3px 3px 0px rgba(20, 20, 0, 0.5);
  border-radius: 15px;
}

.card_dmpo .face {
  position: absolute;

  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_dmpo .face.face1 {
  box-sizing: border-box;
  padding: 20px;
}

.card_dmpo .face.face1 h2 {
  margin: 0;
  padding: 0;
}

.card_dmpo .face.face1 .content {
  font-size: 0.8em;
  margin: 0;
  padding: 0 0 1em 0;
  font-weight: 500;
}

.card_dmpo .face.face2 {
  background: #111;
  transition: 0.5s;
}

.card_dmpo:nth-child(1) .face.face2 {
  background-color: whitesmoke;
  border-radius: 15px;
}

.card_dmpo:nth-child(2) .face.face2 {
  background-color: whitesmoke;
  border-radius: 15px;
}

.card_dmpo:nth-child(3) .face.face2 {
  background-color: whitesmoke;
  /* opacity: 95%; */
  border-radius: 15px;
}

.card_dmpo:hover .face.face2 {
  height: 60px;
  border-radius: 0 0 15px 15px;
}

.card_dmpo .face.face2:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px 0 0 15px;
}

.card_dmpo .face.face2 h2 {
  margin: 0;
  padding: 0;
  font-size: 0.9em;
  color: black;
  transition: 0.5s;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

  text-align: center;
  /* font-weight: bold; */
}

.card_dmpo:hover .face.face2 h2 {
  font-size: 0.8em;
  color: black;
  /* font-weight: bold; */
}
.css-n21gh5 > div {
  margin: 0 !important;
}
/*Agri tech labs hero*/
.css-12icg7e h2 {
  z-index: 99999;
  color: white;
}

.css-aar1hi {
  position: relative;
  z-index: 1;
  background-position: 0px 0px !important;
}

.css-aar1hi::after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.466) !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
/*Blog hero*/
.css-6t5rxk h2 {
  z-index: 99999;
  color: white;
}

.css-74est {
  position: relative;
  z-index: 1;
  background-position: 0px 0px !important;
}
.css-74est::after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.466) !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.css-j7w4t2::before {
  content: "";
  background-color: black;
  position: absolute;
  width: 100vw;
  height: "100%";
}

/*AI labs hero*/

.css-12icg7e h2 {
  z-index: 99999;
  color: white;
}

.css-micxlx {
  position: relative;
  z-index: 1;
  background-position: 0px 0px !important;
}
.css-micxlx::after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.466) !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

/*Elearning hero*/

.css-6t5rxk h2 {
  z-index: 99999;
  color: white;
}

.css-mlbqe6 {
  position: relative;
  z-index: 1;
  background-position: 0px 0px !important;
}
.css-mlbqe6::after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.466) !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

/*R&D hero*/

.css-12icg7e h2 {
  z-index: 99999;
  color: white;
}

.css-1c9hxeh {
  position: relative;
  z-index: 1;
  background-position: 0px 0px !important;
}
.css-1c9hxeh::after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.466) !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

/*Security Labs hero*/

.css-12icg7e h2 {
  z-index: 99999;
  color: white;
}

.css-1p5x70m {
  position: relative;
  z-index: 1;
  background-position: 0px 0px !important;
}
.css-1p5x70m::after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.466) !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

/*About Us*/

.css-6t5rxk h2 {
  z-index: 99999;
  color: white;
}

.css-j7w4t2 {
  position: relative;
  z-index: 1;
  background-position: 0px 0px !important;
}
.css-j7w4t2::after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.466) !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

/*CSR*/

.css-1ssik6v h2 {
  z-index: 99999;
  color: white;
}

.css-7plsrp {
  position: relative;
  z-index: 1;
  background-position: 0px 0px !important;
}
.css-7plsrp::after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.466) !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.footerlink:nth-child(3) > p {
  opacity: 0;
}

/* Cyber */
* {
  margin: 0;
  padding: 0;
  font-size: 1em;
  font-family: Arial, Helvetica, sans-serif;
}
body,
html,
#root body {
  min-width: 350px;
}

header {
  background-color: gray;
  /* height: 100vh; */
  text-align: center;
}

header section {
  position: relative;
  height: 100vh;
}

header article {
  position: absolute;
  padding: 20px;
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
  /* text-transform: uppercase; */
  font-size: 100%;
  font-weight: bolder;
  letter-spacing: 5px;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);

  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

section.primeiraimagem {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  box-shadow: inset 3px 3px 9px rgba(0, 0, 0, 0.466);

  height: 80vh;
}

section#img01 {
  background-image: url(https://res.cloudinary.com/ddyvv0new/image/upload/v1667211561/DAN%20WEBSITE%20IMAGES/IMG-20221031-WA0006-min_ug59yw.jpg);
}

section.normal {
  background-color: #241a32;
  color: white;

  min-height: 300px;
  min-width: 350px;
}

section#section2 {
  background-color: #241a32;
}

section#cor03 {
  background-color: #8b3b3d;
}
.css-1oah76i {
  background-position: top top;
  background-size: auto 100% !important;
  height: 80vh !important;
  min-height: 0px !important;
}
section.imagem {
  position: relative;
  background-color: royalblue;
  min-height: 400px;
  text-align: center;
  box-shadow: inset 3px 3px 9px rgba(0, 0, 0, 0.466);

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

section > h2 {
  font-size: 1.5em;
  padding-top: 75px;
  padding-bottom: 20px;
  text-align: center;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: whitesmoke;

  text-shadow: 1px 1px 9px rgba(0, 0, 0, 0.541);
}

section > p {
  display: block;
  text-align: center;
  padding-bottom: 25px;
  min-width: 350px;
  font-size: 16px;
  line-height: 28px;
}

section.imagem > article {
  font-size: 32px;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: white;

  position: absolute;

  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

section#img02 {
  background-image: url(https://res.cloudinary.com/ddyvv0new/image/upload/v1667211560/DAN%20WEBSITE%20IMAGES/IMG-20221031-WA0013-min_b8gkko.jpg);
}

section#img03 {
  background-image: url(https://images.pexels.com/photos/842654/pexels-photo-842654.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
}

footer {
  height: 100vh;
}

section.imagemfinal {
  position: relative;
  height: 100vh;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

section#imgfinal {
  background-image: url(https://images.pexels.com/photos/219692/pexels-photo-219692.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
}

article.final {
  font-size: 32px;
  letter-spacing: 10px;
  padding: 20px;
  text-transform: uppercase;
  background-color: #241a32;
  color: white;

  position: absolute;

  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}
.css-ptjebv {
  padding-top: 0 !important;
}
.cyber {
  text-align: justify;
  width: 90%;
  align-content: center;
}

/* Full Scroll */
.background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
  will-change: transform;
  backface-visibility: hidden;
  height: 130vh;
  position: fixed;
  width: 100%;
  transform: translateY(30vh);
  transition: all 1.2s cubic-bezier(0.22, 0.44, 0, 1);
}
.background:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
.background:first-child {
  background-image: url(https://res.cloudinary.com/ddyvv0new/image/upload/v1667211561/DAN%20WEBSITE%20IMAGES/IMG-20221031-WA0006-min_ug59yw.jpg);
  transform: translateY(-15vh);
}
.background:first-child .content-wrapper {
  transform: translateY(15vh);
}
.background:nth-child(2) {
  background-image: url(https://res.cloudinary.com/ddyvv0new/image/upload/v1667211564/DAN%20WEBSITE%20IMAGES/CSR_another_option-min_ra3vy1.jpg);
}
.background:nth-child(3) {
  background-image: url(https://res.cloudinary.com/ddyvv0new/image/upload/v1667211560/DAN%20WEBSITE%20IMAGES/IMG-20221031-WA0013-min_b8gkko.jpg);
}
.background:nth-child(4) {
  background-image: url(https://res.cloudinary.com/ddyvv0new/image/upload/v1667211560/DAN%20WEBSITE%20IMAGES/IMG-20221031-WA0002-min_k1aujm.jpg);
}
.background:nth-child(5) {
  background-image: url(https://res.cloudinary.com/ddyvv0new/image/upload/v1667211559/DAN%20WEBSITE%20IMAGES/IMG-20221031-WA0011-min_k2y4ou.jpg);
}
.background:nth-child(6) {
  background-image: url(https://res.cloudinary.com/ddyvv0new/image/upload/v1667211559/DAN%20WEBSITE%20IMAGES/IMG-20221031-WA0003-min_rzalmx.jpg);
}
.background:nth-child(7) {
  background-image: url(https://res.cloudinary.com/ddyvv0new/image/upload/v1667211565/DAN%20WEBSITE%20IMAGES/IMG-20221031-WA0009-min_vbbqyi.jpg);
}
.background:nth-child(8) {
  background-image: url(https://res.cloudinary.com/ddyvv0new/image/upload/v1667211568/DAN%20WEBSITE%20IMAGES/Data_Processing-min_kbvv25.jpg);
}
.background:nth-child(9) {
  background-image: url(https://res.cloudinary.com/ddyvv0new/image/upload/v1667211560/DAN%20WEBSITE%20IMAGES/IMG-20221031-WA0004-min_c3ocku.jpg);
}
.background:nth-child(2) {
  background-image: url(https://res.cloudinary.com/ddyvv0new/image/upload/v1667211559/DAN%20WEBSITE%20IMAGES/IMG-20221031-WA0010-min_tnaofc.jpg);
}

/* Set stacking context of slides */
.background:nth-child(1) {
  z-index: 9;
}

.background:nth-child(2) {
  z-index: 8;
}

.background:nth-child(3) {
  z-index: 7;
}

.background:nth-child(4) {
  z-index: 6;
}

.background:nth-child(5) {
  z-index: 5;
}

.background:nth-child(6) {
  z-index: 4;
}

.background:nth-child(7) {
  z-index: 3;
}

.background:nth-child(8) {
  z-index: 2;
}

.background:nth-child(9) {
  z-index: 1;
}

.content-wrapper {
  height: 60%;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-flow: column nowrap;
  color: #fff;
  font-family: Montserrat;
  /* text-transform: uppercase; */
  transform: translateY(40vh);
  will-change: transform;
  backface-visibility: hidden;
  transition: all 1.7s cubic-bezier(0.22, 0.44, 0, 1);
}
.content-title {
  text-align: center;
  font-size: 5vh;
  line-height: 4;
}

.content-subtitle {
  text-align: center;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.background.up-scroll {
  transform: translate3d(0, -15vh, 0);
}
.background.up-scroll .content-wrapper {
  transform: translateY(15vh);
}
.background.up-scroll + .background {
  transform: translate3d(0, 30vh, 0);
}
.background.up-scroll + .background .content-wrapper {
  transform: translateY(30vh);
}

.background.down-scroll {
  transform: translate3d(0, -130vh, 0);
}
.background.down-scroll .content-wrapper {
  transform: translateY(40vh);
}
.background.down-scroll + .background:not(.down-scroll) {
  transform: translate3d(0, -15vh, 0);
}
.background.down-scroll + .background:not(.down-scroll) .content-wrapper {
  transform: translateY(15vh);
}
/* @media (min-width: 992px) {
  .container {
    max-width: 1760px !important;
  }
} */
@media (max-width: 992px) {
  nav > div > ul > div {
    zoom: 0.85 !important;
  }
}

@media screen and (max-width: 600px) {
  .card p {
    zoom: 0.9;
    font-size: 1.1rem !important;
  }
}
.card {
  overflow: hidden !important;
  min-width: 400px !important;
}
.chakra-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.chakra-button label {
  margin-bottom: 0px !important;
}
